import { h, Component } from 'preact';
import axios from 'axios';
import { scheme, server } from '../../assets/server/server';
import { route } from 'preact-router';
import Match, { Link } from 'preact-router/match';

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			message: '',

			isLoading: false
		}
	}

	handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        });
    }

	submit = e => {
        console.log('Submitted form.');
        console.log(this.state);

        // Loading
        this.setState({
            isLoading: true
        })


        // Send
        axios.post(`${scheme}://${server}/api/contact-us`, {
			...this.state,
			fullName: `${this.state.firstName} ${this.state.lastName}`,
        }).then(res => {
            console.log(res);

            // Route
			route('/confirmation')
			location.reload()

            // Loading
            this.setState({
                isLoading: false
            })
        })
        
        e.preventDefault();
    }

	render(_, {firstName, lastName, email, phoneNumber, message, isLoading}) { 
		return (
			<nav class="navbar navbar-expand-md navbar-light d-md-flex">
				<div class="container-fluid position-relative">
					{/* Toggler */}
					<button class="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon" />
					</button>

					{/* Brand */}
					<Match path="/">
					{ ({ matches }) => matches && (
						<a class="navbar-brand position-absolute mr-auto d-none d-lg-block" style="right: 45%; top: 0px; z-index: 1050;" href="/">
							<img src="./assets/img/phone-rag-logo-min.png" alt="PhoneRag Logo" class="navbar-brand-img" style="max-height: 10rem !important" />
						</a>
					) }
					</Match>
					<Match path="/">
					{ ({ matches }) => !matches && (
						// <a class="navbar-brand mr-auto d-none d-lg-block" href="/">
						// 	<img src="./assets/img/phone-rag-logo-min.png" alt="PhoneRag Logo" class="navbar-brand-img" style="transform: scale(2);" />
						// </a>
						<a class="navbar-brand position-absolute mr-auto d-none d-lg-block" style="right: 45%; top: 0px; z-index: 1050;" href="/">
							<img src="./assets/img/phone-rag-logo-min.png" alt="PhoneRag Logo" class="navbar-brand-img" style="max-height: 10rem !important" />
						</a>
					) }
					</Match>
					
					<a class="navbar-brand mr-auto d-block d-lg-none" href="/">
						<img src="./assets/img/phone-rag-logo-min.png" alt="PhoneRag Logo" class="navbar-brand-img" style="max-height: 4rem !important" />
					</a>

					{/* Navigation */}
					<ul class="collapse navbar-nav navbar-collapse mr-lg-auto order-lg-first" id="navbar">
						<li class="nav-item">
							<Link activeClassName="active" class="nav-link " href="/business-account">
								Open Business Account
							</Link>
						</li>
						<li class="nav-item">
							<Link activeClassName="active" class="nav-link " href="/faq">
								FAQ
							</Link>
						</li>
						<li class="nav-item">
							<Link activeClassName="active" class="nav-link " href="/contact-us">
								Contact Us
							</Link>
						</li>
						<li class="nav-item d-block d-md-none mt-2">
							<a href="tel:+18887880101" class="btn btn-light">
							Call us: <b>888.788.0101</b>
							</a>
						</li>
					</ul>

					{/* Buttons */}
					<div class="navbar-user d-none d-md-block mr-4">
						{/* Call */}
						<a href="tel:+18887880101" class="btn btn-light mr-3">
						Call us: <b>888.788.0101</b>
						</a>

						{/* Get in touch */}
						<a href="#!" class="btn btn-primary" data-toggle="modal" data-target="#contactModal">
						Get in touch
						</a>
					</div>
				</div>

				<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-card card">
								<div class="card-header">
									{/* Title */}
									<h4 class="card-header-title" id="exampleModalCenterTitle">
									Get In Touch
									</h4>

									{/* Close */}
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>

								</div>

								<div class="card-body">
									<form onSubmit={this.submit}>
										<div class="form-row">
											<div class="col-12 col-md-6 mb-3">
												<label for="firstName">First name <span class="text-danger">*</span></label>
												<input type="text" class="form-control" id="firstName" placeholder="First name" name="firstName" value={firstName} onChange={this.handleInputChange} required />
											</div>

											<div class="col-12 col-md-6 mb-3">
												<label for="lastName">Last name <span class="text-danger">*</span></label>
												<input type="text" class="form-control" id="lastName" placeholder="Last name" name="lastName" value={lastName} onChange={this.handleInputChange} required />
											</div>
										</div>

										<div class="form-row">
											<div class="col-12 col-md-6 mb-3">
												<label for="email">Email address <span class="text-danger">*</span></label>
												<input type="email" class="form-control" id="email" placeholder="Email address" name="email" value={email} onChange={this.handleInputChange} required />
											</div>

											<div class="col-12 col-md-6 mb-3">
												<label for="phoneNumber">Phone number <span class="text-danger">*</span></label>
												<input type="tel" class="form-control" id="phoneNumber" placeholder="Phone number" name="phoneNumber" value={phoneNumber} onChange={this.handleInputChange} required />
											</div>
										</div>

										<div class="form-group">
											<label for="message">Message <span class="text-danger">*</span></label>
											<textarea class="form-control" id="message" rows="3" placeholder="What are you inquiring about?" name="message" value={message} onChange={this.handleInputChange} required />
										</div>

										{/* Submit */}
										<button type="submit" class="btn btn-primary" disabled={isLoading}>
											{
                                                isLoading ? (
                                                    <span>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                        <span class="sr-only">Loading...</span>
                                                    </span>
                                                ) : (
                                                    <span>Submit</span>
                                                )
                                            }
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}

export default Header;
