import { Component, h } from 'preact';
import { Router } from 'preact-router';

import ReactGA from 'react-ga';

// Code-splitting is automated for `routes` directory
import Header from './header';
import Home from '../routes/home';
import FAQ from '../routes/faq';
import Footer from './footer';
import BusinessAccount from '../routes/businessAccount';
import ContactUs from '../routes/contactUs'
import Confirmation from '../routes/confirmation';
import Terms from '../routes/terms';
import Privacy from '../routes/privacy';

class App extends Component {
	componentWillMount() {
		ReactGA.initialize("UA-186720870-1");
	}

	handleRoute = (e) => {
		if (typeof window !== "undefined") {
			ReactGA.pageview(window.location.pathname + window.location.search);
			window.scrollTo({ top: 0 });
		}
	};

	render() {
		return (
			<div id="app">
				<Header />
				<div style="min-height: 100vh;">
					<Router onChange={this.handleRoute}>
						<Home path="/" />
						<BusinessAccount path="/business-account" />
						<ContactUs path="/contact-us" />
						<FAQ path="/faq" />
						<Confirmation path="/confirmation" />
						<Terms path="/terms-and-conditions" />
						<Privacy path="/privacy" />
					</Router>
				</div>
				<Footer />
			</div>
		)
		}
}

export default App;
