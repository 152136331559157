import { h } from 'preact';

const Footer = () => (
    <div class="py-4 py-md-6 bg-primary border-top border-gray-800-50">
        <div id="footer">
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4 col-lg-3 text-center">
                            <a href="/">
                                <img src="../assets/img/phone-rag-logo-min.png" alt="PhoneRag Logo" class="d-inline-block mb-3" style="height: 5rem;" />
                                <h6 class="text-white text-uppercase mb-4 mb-md-0">Smartphone Cleaner</h6>
                                <h6 class="text-white font-weight-normal mt-2">PhoneRag 2020</h6>
                            </a>
                        </div>
                        
                        <div class="col-6 col-md-4 col-lg-3 text-center">
                            <h6 class="font-weight-bold text-uppercase text-white">Product</h6>
                            
                            <ul class="list-unstyled text-white mb-6 mb-lg-0">
                                <li class="mb-3">
                                    <a href="/faq" class="text-reset">FAQ</a>
                                </li>

                                <li class="mb-3">
                                    <a href="/#!" class="text-reset">Buy Now (coming soon)</a>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="col-6 col-md-4 col-lg-3 text-center">
                            <h6 class="font-weight-bold text-uppercase text-white">For Businesses</h6>
                            
                            <ul class="list-unstyled text-white mb-0">
                                <li class="mb-3">
                                    <a href="/business-account" class="text-reset">Open Business Account</a>
                                </li>
                                
                                <li class="mb-3">
                                    <a href="/contact-us" class="text-reset">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="col-6 col-md-4 offset-md-3 col-lg-2 offset-lg-0 text-center">
                            <h6 class="font-weight-bold text-uppercase text-white">Legal</h6>
                            
                            <ul class="list-unstyled text-white mb-0">
                                <li class="mb-3">
                                    <a href="/privacy" target="_blank" class="text-reset">Privacy</a>
                                </li>
                                
                                <li class="mb-3">
                                    <a href="/terms-and-conditions" target="_blank" class="text-reset">Terms</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
);

export default Footer;
